import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

window.Swal = Swal;

const confirmed = (element, result) => {
    if (result.value) {
        if (!!element.getAttribute('data-remote')) {
            let button = Ladda.create(element);
            let reloadAfterSuccess = !!element.getAttribute('data-reload');
            let redirectAfterSuccess = element.getAttribute('data-redirect');

            button.start();

            $.ajax({
                method:          element.getAttribute('data-method') || 'GET',
                url:             element.getAttribute('href'),
                dataType:        'json',
                skipGlobalError: true,
                success: function(result) {
                    Swal.fire(I18n.t('shared.success') + '!', result.message || '', 'success')
                        .then((_result) => {
                            if (redirectAfterSuccess && redirectAfterSuccess.length > 0) {
                                window.location.href = redirectAfterSuccess;
                            } else if (reloadAfterSuccess) {
                                window.location.reload();
                            }
                        });
                },
                error: function(xhr) {
                    let title   = I18n.t('shared.error') + '!';
                    let message = I18n.t('notifications.something_wrong');

                    if (xhr.responseJSON && xhr.responseJSON.message) {
                        message = xhr.responseJSON.message;
                    } else if (xhr.responseJSON && xhr.responseJSON.error_desc) {
                        title   = xhr.responseJSON.error;
                        message = xhr.responseJSON.error_desc;
                    }

                    Swal.fire(title, message,'error');
                },
                complete: function() {
                    button.stop();
                }
            });
        } else {
            element.removeAttribute('data-confirm-swal');
            element.click();
        }
    }
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
    const message = element.getAttribute('data-confirm-swal');
    const text    = element.getAttribute('data-text');

    Swal.fire({
        title:             message || I18n.t('shared.confirm'),
        text:              text || '',
        icon:              'warning',
        showCancelButton:  true,
        confirmButtonText: I18n.t('shared.yes'),
        cancelButtonText:  I18n.t('buttons.cancel'),
    }).then(result => confirmed(element, result));
};

const allowAction = (element) => {
    if (element.getAttribute('data-confirm-swal') === null) {
        return true;
    }

    showConfirmationDialog(element);
    return false;
};

function handleConfirm(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element);
    }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);